import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

export class ReviewDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false
    };
  }

  checkedCats = e => {
    if (this.props.showDocCategories().includes(e) === true) {
      this.checkedItemsReview.push(e);
      return true;
    } else {
      return false;
    }
  };

  handleReviewChangeChekbox = e => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    var reviewarr = this.checkedItemsReview;

    if (isChecked) {
      reviewarr.push(value);
    } else {
      reviewarr = reviewarr.filter(e => e !== value);
    }

    this.checkedItemsReview = reviewarr;

    if (reviewarr.length <= 0) {
      this.button.disabled = true;
    } else {
      this.button.disabled = false;
    }
  };

  getFileExtension(filename) {
    return filename.split(".").pop();
  }

  componentDidMount() {
    //this.setState({ checkedItemsReview: this.props.showDocCategories() });
  }

  render() {
    this.checkedItemsReview = [];
    return (
      <Modal
        show={this.props.state.isReviewModalOpen}
        onHide={this.props.hideReviewModal}
        dialogClassName={"modal-90w"}
      >
        <Modal.Body>
          <div className="review-document-popup-body">
            <div className="review-doc-details align-self-center">
              <div className="review-doc-detailsIn">
                <h1>Review Document</h1>
                <h2>What is included in this document?</h2>

                <ul className="">
                  {this.props.state.documentCategories.map(item => (
                    <li key={item.doc_type}>
                      <label className="checkbox-container">
                        {item.doc_name}

                        <input
                          name="docType"
                          type="checkbox"
                          value={item.doc_type}
                          onChange={this.handleReviewChangeChekbox}
                          defaultChecked={this.checkedCats(item.doc_type)}
                        />

                        <span className="checkmark"></span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="review-doc-img">
              {this.props.state.uploadeddocuments.map(uploadeditem => {
                if (uploadeditem.doc_id === this.props.state.displayDocId) {
                  var docExt = uploadeditem.doc_name_client.split(".").pop();

                  if (docExt === "pdf") {
                    return (
                      <iframe
                        src={uploadeditem.doc_name_local}
                        width="100%"
                        height="100%"
                        scrolling="no"
                        key="{uploadeditem.doc_id}"
                        title="iframe"
                      ></iframe>
                    );
                  } else {
                    return (
                      <img
                        key="{uploadeditem.doc_id}"
                        src={uploadeditem.doc_name_local}
                        alt="doc"
                      />
                    );
                  }
                } else return "";
              })}
            </div>

            <div className="popup-footer">
              <div className="popup-footerIn">
                <button
                  type="button"
                  className="btn btn-bordered"
                  onClick={this.props.hideReviewModal}
                >
                  Close
                </button>

                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    this.props.handleClickUpdate(
                      this.props.state.displayDocId,
                      this.props.state.displayDocInsureId,
                      this.checkedItemsReview
                    );
                  }}
                  ref={button => (this.button = button)}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ReviewDocumentModal;
