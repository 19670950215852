import React, { Component } from "react";
import icon1 from "./../assets/images/icon1.png";
import icon2 from "./../assets/images/icon2.png";
import icon3 from "./../assets/images/icon3.png";
import icon4 from "./../assets/images/icon4.png";
import icon5 from "./../assets/images/icon5.png";
import { Uploadservice } from "./../services/Uploadservice";
import Dropzone from "react-dropzone";
import UploadDocModal from "./../common/UploadDocModal";
import MissingDocModal from "./../common/MissingDocModal";
import DeleteDocumentModal from "./../common/DeleteDocumentModal";
import LoadingModal from "./../common/LoadingModal";
import ReviewDocumentModal from "./../common/ReviewDocumentModal";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import ExitWarningModal from "../common/ExitWarningModal";
export class Home extends Component {
  constructor(props) {
    const query = JSON.parse(localStorage.getItem("storeObj"));
    super(props);
    this.state = {
      error: null,
      isExitWarningModal: false,
      isMissingCategoryModal: false,
      isLoadingModal: false,
      isDeleteDocumentModal: false,
      isUploadDocumentModal: false,
      documentCategories: [],
      uploadeddocuments: [],
      fileurl: null,
      checkedItems: [],
      displayDocId: null,
      displayDocInsureId: null,
      filetype: "image",
      fileobj: {},
      ocrAssetId: query.asset_id !== undefined ? query.asset_id : ""
    };

    this.uploadservice = new Uploadservice();
    this.missingDocModalClose = this.missingDocModalClose.bind(this);
    this.showDocCategories = this.showDocCategories.bind(this);
  }
  successnotify = message => toast.success(message);
  errornotify = message => toast.error(message);

  exitWarningModalFunction = event => {
    if (this.props.userPopupModals.exitFormModal)
      this.setState({ isExitWarningModal: true });
    else
      window.parent.postMessage("closeIframe", "*");
  };

  ExitWarningModalConfirm = event => {
    if (this.childExitWarning.state.isHide) {
      this.props.userPopupModals.exitFormModal = false;
      this.uploadservice
        .updateUserModalPopup(this.props.userPopupModals)
        .then(res => {
          if (res.status === false) {
            this.errornotify("Something went wrong..");
          }
        });
    }
    this.setState({ isExitWarningModal: false });
    window.parent.postMessage("closeIframe", "*");
  };

  hideExitWarningModal = event => {
    this.setState({ isExitWarningModal: false });
  };

  _onParent = e => {
    if (this.state.uploadeddocuments.length === 0) {
      e.preventDefault();
    }
    if (this.props.isTabWorking) {
      this.props.history.push("/policies");
    } else {
      e.preventDefault();
    }
  };

  // Start - Upload documents fucntions

  onDrop = files => {
    if (
      files[0].type === "application/wps-office.pdf" ||
      files[0].type === "application/pdf" ||
      files[0].type === "image/png" ||
      files[0].type === "image/jpeg" ||
      files[0].type === "image/jpg"
    ) {
      if (
        files[0].type === "application/wps-office.pdf" ||
        files[0].type === "application/pdf"
      ) {
        this.setState({ filetype: "pdf" });
      } else {
        this.setState({ filetype: "image" });
      }

      this.setState({
        fileurl: URL.createObjectURL(files[0]),
        fileobj: files[0]
      });
      this.handleModal();
    } else {
      this.errornotify("Only PDF, jpeg, png fle types accepted");
    }
  };

  handleModal = event => {
    this.setState({ isUploadDocumentModal: !this.state.isUploadDocumentModal });
  };

  showReviewModal = (id, insured_id) => {
    this.setState({
      isReviewModalOpen: true,
      displayDocId: id,
      displayDocInsureId: insured_id
    });
  };

  hideReviewModal = () => {
    this.setState({ isReviewModalOpen: false });
  };

  handleClickUpdate = (doc_id, insured_id, docCategries) => {
    if (insured_id === undefined) {
      this.errornotify(
        "You can not update this document, please click on close button."
      );
      return false;
    }
    let checkedReviewDocCategoies = JSON.stringify({ ...docCategries });

    let datanew = JSON.stringify({
      doc_id: doc_id,
      insured_id: insured_id,
      docCategries: checkedReviewDocCategoies
    });
    let tempthis = this;

    this.uploadservice.updateuploadedDocument(datanew).then(res => {
      if (res.status) {
        tempthis.documentsuccessfullyUpdated(doc_id, docCategries);
      } else {
        tempthis.errornotify(res.msg);
      }
    });
  };

  async documentsuccessfullyUpdated(doc_id, docCategries) {
    this.state.uploadeddocuments.forEach((item, i) => {
      if (item.doc_id === doc_id) {
        let newuploadeddoc = [...this.state.uploadeddocuments];
        newuploadeddoc[i].docCategries = JSON.stringify({ ...docCategries });
      }
    });

    this.setState(state => {
      const uploadeddocuments = this.state.uploadeddocuments;
      this.state.documentCategories.forEach(itemcat => {
        itemcat.hasdoc = [];
        [...uploadeddocuments].forEach(itemdoc => {
          if (itemdoc.docCategries.includes(itemcat.doc_type)) {
            itemcat.hasdoc.push(itemdoc.originalname);
          }
        });
      });
    });

    this.hideReviewModal();
    this.successnotify("Document successfully updated..");
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  handleSubmitForm = event => {
    this.handleModal();
    this.setState({ isLoadingModal: true });
    let myForm = document.getElementById("myForm");
    let formData = new FormData(myForm);
    if (this.state.fileobj) {
      formData.append("file", this.state.fileobj);
    }
    let tempthis = this;

    let checkedDocCategoies = JSON.stringify({ ...this.state.checkedItems });
    formData.append("docCategries", checkedDocCategoies);
    this.uploadservice.uploadedDocumentsService(formData).then(res => {
      if (res.status) {
        tempthis.uploadedDocumentsuccessfully(res.data);
      } else {
        tempthis.setState({ isLoadingModal: false });
        tempthis.errornotify(res.msg);
      }
    });
  };

  async uploadedDocumentsuccessfully(data) {
    let arr = JSON.parse(data.docCategries);
    if (Object.values(arr).indexOf("accord") > -1) {
      await this.uploadservice.sendForOcr(data.doc_name_client).then(res => {
        this.props.changeOcrAssetId(res);
      });
    }
    this.setState({ isLoadingModal: false, checkedItems: [] });
    this.setState(state => {
      const uploadeddocuments = [...this.state.uploadeddocuments, data];

      let newDocumentObject = [...this.state.documentCategories].forEach(
        itemcat => {
          itemcat.hasdoc = [];
          [...uploadeddocuments].forEach(itemdoc => {
            if (itemdoc.docCategries.includes(itemcat.doc_type)) {
              itemcat.hasdoc.push(itemdoc.originalname);
            }
          });
        }
      );

      return {
        uploadeddocuments,
        newDocumentObject
      };
    });

    this.successnotify("Document successfully uploaded.");
  }

  handleChangeChekbox = e => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      this.state.checkedItems.push(value);
    } else {
      const index = this.state.checkedItems.indexOf(value);
      if (index > -1) {
        this.state.checkedItems.splice(index, 1);
      }
    }
    this.setState({ checkedItems: this.state.checkedItems });
  };

  //  End - Upload documents fucntions

  showDocCategories = () => {
    let catarr = [];
    this.state.uploadeddocuments.forEach(uploadeditemdoc => {
      if (uploadeditemdoc.doc_id === this.state.displayDocId) {
        let docCategoriesObj = JSON.parse(uploadeditemdoc.docCategries);

        Object.values(docCategoriesObj).forEach(myitem => {
          catarr.push(myitem);
        });
      }
    });
    return catarr;
  };

  handleClickDelete = doc_id => {
    let datanew = { doc_id: doc_id };
    let tempthis = this;

    this.uploadservice.deleteUploadedDocument(datanew).then(res => {
      tempthis.hideDeleteDocumentModal();
      if (res.status) {
        tempthis.removeUploadedDocument(doc_id);
      } else {
        tempthis.errornotify(res.msg);
      }
    });
  };

  removeUploadedDocument = e => {
    this.setState(state => {
      const uploadeddocuments = state.uploadeddocuments.filter(
        item => item.doc_id !== e
      );
      let newDocumentObject = [...this.state.documentCategories].forEach(
        itemcat => {
          itemcat.hasdoc = [];
          [...uploadeddocuments].forEach(itemdoc => {
            if (itemdoc.docCategries.includes(itemcat.doc_type)) {
              itemcat.hasdoc.push(itemdoc.originalname);
            }
          });
        }
      );

      return {
        uploadeddocuments,
        newDocumentObject
      };
    });

    this.successnotify("Document successfully deleted..");
  };

  showDeleteDocumentModal = (id, insured_id) => {
    this.setState({
      isDeleteDocumentModal: true,
      displayDocId: id,
      displayDocInsureId: insured_id
    });
  };

  hideDeleteDocumentModal = () => {
    this.setState({ isDeleteDocumentModal: false });
  };

  async handleModalContinue(event) {
    var isConfirmation = true;

    await this.state.documentCategories.forEach(item => {
      if (
        item.hasdoc &&
        item.hasdoc.length === 0 &&
        this.props.userPopupModals.missingDocModal
      ) {
        isConfirmation = false;
        this.setState({
          isMissingCategoryModal: this.props.userPopupModals.missingDocModal
        });
      }
    });

    if (isConfirmation) {
      this.props.history.push("/policies");
    }
  }

  async missingDocModalClose(event) {
    this.setState({ isMissingCategoryModal: false });
  }

  missingDocModalContinue = e => {
    if (this.child.state.isHide) {
      this.props.userPopupModals.missingDocModal = false;
      this.uploadservice
        .updateUserModalPopup(this.props.userPopupModals)
        .then(res => {
          if (res.status === false) {
            this.errornotify("Some went wrong..");
          } else {
            this.props.changeUserModalBox(this.props.userPopupModals);
          }
        });
    }
    this.props.history.push("/policies");
  };

  async componentDidMount() {
    let ud, dc;
    await this.uploadservice.getUploadeddocuments().then(res => {
      ud = res;
    });
    await this.uploadservice.getDocumentCategory().then(res => {
      dc = res;
    });

    this.setState(
      {
        uploadeddocuments: ud,
        documentCategories: dc
      },
      async () => {
        if (this.state.ocrAssetId !== "") {
          await this.uploadservice
            .getOcrData(this.state.ocrAssetId)
            .then(res => {
              console.log(res);
              let url = res.url;
//              let filename = url.substring(url.lastIndexOf("/") + 1);
              let filename = 'upload.pdf';
              this.setState(prevState => ({
                uploadeddocuments: [
                  ...prevState.uploadeddocuments,
                  {
                    docCategries: JSON.stringify({ "0": "accord" }),
                    originalname: filename,
                    doc_name_local: url,
                    doc_id: 1234567890,
                    sortOrder: 1,
                    doc_name_client: filename
                  }
                ]
              }));
              
//              let newDocumentObject = [...this.state.documentCategories].forEach(
//                  itemcat => {
//                    itemcat.hasdoc = [];
//                    [...this.state.uploadeddocuments].forEach(itemdoc => {
//                      if (itemdoc.docCategries.includes(itemcat.doc_type)) {
//                        itemcat.hasdoc.push(itemdoc.originalname);
//                      }
//                    });
//                  }
//                );
//        
//              this.setState({ newDocumentObject });
//              this.handleSubmitForm();
              this.props.history.push("/policies");
            }); 
        }

        let newDocumentObject = [...this.state.documentCategories].forEach(
          itemcat => {
            itemcat.hasdoc = [];
            [...this.state.uploadeddocuments].forEach(itemdoc => {
              if (itemdoc.docCategries.includes(itemcat.doc_type)) {
                itemcat.hasdoc.push(itemdoc.originalname);
              }
            });
          }
        );
        this.setState({ newDocumentObject });
      }
    );
  }

  render() {
    const { documentCategories, uploadeddocuments } = this.state;

    return (
      <React.Fragment>
        <form id="myForm">
          <div className="custom-container">
            <div className="custom-popup submitInsurance">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    <img src={icon1} alt="icon1" /> SUBMIT INSURANCE
                  </h5>
                  <button
                    type="button"
                    onClick={() => this.exitWarningModalFunction()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img src={icon2} alt="icon2" />{" "}
                  </button>
                </div>
                <div className="modal-body">
                  <div className="custom-breadcrumb desktop">
                    <div className="step1">
                      <Link to="/">1. Upload Documents </Link>
                    </div>
                    <div className="step2">
                      <Link to="/policies" onClick={e => this._onParent(e)}>
                        2. Check Policies
                      </Link>
                    </div>
                  </div>
                  <div className="review-document-popup-body">
                    <div className="review-doc-details align-self-center">
                      <div className="step1 mobile">
                        <span>1. Upload Documents</span>
                      </div>
                      <div className="review-doc-detailsIn">
                        <h3>Upload Documents</h3>
                        <div className="upload-btn-wrapper">
                          <Dropzone onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps }) => (
                              <section className="container">
                                <div
                                  {...getRootProps({ className: "dropzone" })}
                                >
                                  <input {...getInputProps()} />
                                  <button className="btn" type="button">
                                    <img src={icon3} alt="icon3" /> Choose a
                                    File or Drag & Drop
                                  </button>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        <p>
                          Choose a fle or drag and drop a fle to upload. <br />{" "}
                          Only PDF, jpeg, png fle types accepted.
                        </p>

                        {uploadeddocuments.map(uploadeditem => (
                          <span
                            className="uploaded-doc-name"
                            key={uploadeditem.doc_id}
                          >
                            <img src={icon4} alt="icon4" className="tick" />
                            <p
                              onClick={() =>
                                this.showReviewModal(
                                  uploadeditem.doc_id,
                                  uploadeditem.insured_id
                                )
                              }
                            >
                              {uploadeditem.originalname}{" "}
                            </p>
                            <a
                              href="#!"
                              onClick={() =>
                                this.showDeleteDocumentModal(
                                  uploadeditem.doc_id,
                                  uploadeditem.insured_id
                                )
                              }
                            >
                              <img src={icon5} alt="icon5" />
                            </a>
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="review-doc-img">
                      <div className="step2 mobile">
                        <span>2. Check Policies</span>
                      </div>
                      <h1>Required Documents</h1>
                      <ul className="doc-list circle">
                        {documentCategories.map(item => (
                          <li
                            className={
                              item.hasdoc && item.hasdoc.length === 0
                                ? ""
                                : "done"
                            }
                            key={item.doc_id}
                          >
                            {item.doc_name}
                            {item.hasdoc
                              ? item.hasdoc.map((filename, index) => (
                                  <span key={index}> {filename} </span>
                                ))
                              : console.log("not found")}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="popup-footer">
                      <div className="popup-footerIn">
                        <button
                          type="button"
                          onClick={() => this.exitWarningModalFunction()}
                          className="btn btn-bordered"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          disabled={uploadeddocuments.length === 0}
                          onClick={() => {
                            this.handleModalContinue();
                          }}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <UploadDocModal
          state={this.state}
          handleChangeChekbox={this.handleChangeChekbox}
          handleModal={this.handleModal}
          handleSubmitForm={this.handleSubmitForm}
        />

        <MissingDocModal
          state={this.state}
          missingDocModalClose={this.missingDocModalClose}
          missingDocModalContinue={this.missingDocModalContinue}
          ref={instance => {
            this.child = instance;
          }}
        />

        <DeleteDocumentModal
          state={this.state}
          hideDeleteDocumentModal={this.hideDeleteDocumentModal}
          handleClickDelete={this.handleClickDelete}
          showDocCategories={this.showDocCategories}
        />

        <ReviewDocumentModal
          state={this.state}
          hideReviewModal={this.hideReviewModal}
          showDocCategories={this.showDocCategories}
          handleReviewChangeChekbox={this.handleReviewChangeChekbox}
          handleClickUpdate={this.handleClickUpdate}
        />

        <ExitWarningModal
          isExitWarningModal={this.state.isExitWarningModal}
          hideExitWarningModal={this.hideExitWarningModal}
          ExitWarningModalConfirm={this.ExitWarningModalConfirm}
          ref={instance => {
            this.childExitWarning = instance;
          }}
        />

        <LoadingModal isLoadingModal={this.state.isLoadingModal} />

        <ToastContainer />
      </React.Fragment>
    );
  }
}

const MapStateToProps = state => {
  return {
    isTabWorking: state.isTabWorking,
    userPopupModals: state.userPopupModals
  };
};

const MapDispatchToProps = dispatch => {
  return {
    changeIsTabWorking: isTabWorking => {
      dispatch({ type: "CHANGE_VALUE", payload: isTabWorking });
    },
    changeUserModalBox: userPopupModals => {
      dispatch({ type: "CHANGE_VALUE_MODAL", payload: userPopupModals });
    },
    changeOcrAssetId: id => {
      dispatch({ type: "CHANGE_ORC_ASSETS_ID", payload: id });
    },
    changePolicyFormData: data => {
      dispatch({ type: "CHANGE_POLICY_FORM_DATA", payload: data });
    },
    changeisWelcomeModal: isWelcomeModal => {
      dispatch({ type: "CHANGE_WELCOME_MODAL_VALUE", payload: isWelcomeModal });
    }
  };
};

export default connect(MapStateToProps, MapDispatchToProps)(Home);
