import React from "react";
import Modal from "react-bootstrap/Modal";
import PDF from "react-pdf-js-infinite";

export default function UploadDocModal({
  handleChangeChekbox,
  handleModal,
  handleSubmitForm,
  state
}) {
  const {
    documentCategories,
    fileurl,
    checkedItems,
    isUploadDocumentModal,
    filetype
  } = state;

  let url;
  if (filetype === "image") {
    url = <img src={fileurl} alt="document-url" />;
  } else {
    url = <PDF file={fileurl} />;
  }

  return (
    <Modal
      show={isUploadDocumentModal}
      onHide={() => {
        handleModal();
      }}
      dialogClassName={"modal-90w"}
    >
      <Modal.Body>
        <div className="review-document-popup-body">
          <div className="review-doc-details align-self-center">
            <div className="review-doc-detailsIn">
              <h1>Review Document</h1>
              <h2>What is included in this document?</h2>
              <ul className="">
                {documentCategories.map(item => (
                  <li key={item.doc_type}>
                    <label className="checkbox-container">
                      {item.doc_name}
                      <input
                        name="docType"
                        type="checkbox"
                        value={item.doc_type}
                        onChange={handleChangeChekbox}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="review-doc-img">{url}</div>
          <div className="popup-footer">
            <div className="popup-footerIn">
              <button
                type="button"
                className="btn btn-bordered"
                onClick={() => {
                  handleModal();
                }}
              >
                Close
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  handleSubmitForm();
                }}
                disabled={checkedItems.length === 0}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
