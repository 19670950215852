import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import close from "./../assets/images/close.png";
import icon9 from "./../assets/images/icon9.png";

//export default function ExitWarningModal({ isExitWarningModal , hideExitWarningModal }) {

export class ExitWarningModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHide: false
    };
  }

  handleChange = e => {
    console.log(this.refs.check_me.checked);
    this.setState({ isHide: this.refs.check_me.checked });
  };

  render() {
    return (
      <Modal
        show={this.props.isExitWarningModal}
        onHide={this.props.hideExitWarningModal}
        dialogClassName={"modal-dialog-centered customModal red"}
      >
        <Modal.Header>
          <img src={close} alt="close" />
          <button
            type="button"
            className="close"
            onClick={this.props.hideExitWarningModal}
          >
            <img src={icon9} alt="icon9" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <h4>Warning!</h4>
          <p>Are you sure you want to exit?</p>
          <p>Progress will not be saved.</p>
          <div className="btn-box">
            <button
              type="button"
              className="btn btn-bordered"
              onClick={this.props.hideExitWarningModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.props.ExitWarningModalConfirm}
            >
              Exit
            </button>
          </div>
          <label className="checkbox-container">
            Don’t Remind Me Again
            <input
              type="checkbox"
              name="exitFormModalCheckbox"
              ref="check_me"
              onChange={this.handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ExitWarningModal;
