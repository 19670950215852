import { Component } from "react";
import queryString from "query-string";

export class Uploadservice extends Component {
  constructor(props) {
    const query = JSON.parse(localStorage.getItem("storeObj"));
    super(props);
    this.state = {
      insured_id: query === null ? 0 : parseInt(query.insured_id),
      requirements_id: query === null ? 0 : parseInt(query.requirements_id),
      user_id: query === null ? 0 : parseInt(query.user_id),
      user_pins_token: query === null ? 0 : query.user_pins_token,
      api_url: "https://nscfq6l7t8.execute-api.us-west-1.amazonaws.com/Test/"
    //  api_url: "https://upload.pinsocr.com:3001//"
    };
  }
// http://upload.pinsocr.com:3001/
// http://18.220.147.112:3001/
  async getLogin() {
    var query = queryString.parse(window.location.search);

    if (Object.keys(query).length > 0) {
      localStorage.setItem("storeObj", JSON.stringify(query));
    } else {
      query = JSON.parse(localStorage.getItem("storeObj"));
    }

    return await fetch(this.state.api_url + "login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Date": Date.now()
      },
      body: JSON.stringify({
        user_id: query.user_id,
        pins_token: query.user_pins_token,
        requirements_id: query.requirements_id,
        insured_id: query.insured_id
      })
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return data;
      });
  }

  async getUploadeddocuments() {
    return Promise.resolve(
      (this.uploadedDocuments = fetch(this.state.api_url + "getdocument", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Date": Date.now(),
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({
          user_id: this.state.user_id,
          insured_id: this.state.insured_id,
          requirements_id: this.state.requirements_id,
          pins_token: this.state.user_pins_token
        })
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          if (data.status) {
            //console.log(data.doc);
            return Object.values(data.doc);
          } else {
            return [];
          }
        }))
    );
  }

  /*async getOcrData(filename) {
    return Promise.resolve(
      (this.ocrData = fetch(this.state.api_url + "getocrresponse", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({
          user_id: this.state.user_id,
          pins_token: this.state.user_pins_token,
          requirements_id: this.state.requirements_id,
          insured_id: this.state.insured_id,
          filename: filename
        })
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          if (data.status) {
            try {
              let res = JSON.parse(data.data);
              return res.ocr_results.limits;
            } catch (e) {
              return [];
            }
          } else {
            return [];
          }
        }))
    );
  }*/

  async getOcrData(ocrAssetId) {
    return Promise.resolve(
      (this.ocrData = fetch(this.state.api_url + "getocrresponse", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Date": Date.now(),
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({
          user_id: this.state.user_id,
          pins_token: this.state.user_pins_token,
          requirements_id: this.state.requirements_id,
          asset_id: ocrAssetId
        })
      })
        .then(function(response) {
          console.log('getOcrData response');
          console.log(response);
          return response.json();
        })
        .then(function(data) {
          if (data.status) {
            try {
              console.log(data);
              let res = JSON.parse(data.data);
              console.log(res);
              res.ocr_results.url = res.url
              return res.ocr_results;
            } catch (e) {
              return [];
            }
          } else {
            return [];
          }
        }))
    );
  }

  async sendForOcr(filename) {
    return Promise.resolve(
      (this.asset_id = fetch(this.state.api_url + "getocr", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Date": Date.now(),
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({
          user_id: this.state.user_id,
          pins_token: this.state.user_pins_token,
          requirements_id: this.state.requirements_id,
          insured_id: this.state.insured_id,
          filename: filename
        })
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          console.log(data);
          console.log(data.status);
          if (data.status) {
            try {
              console.log("sendForOcr: res");
              console.log(data.data);
              return data.data;
            } catch (e) {
              return "";
            }
          } else {
            return "";
          }
        }))
    );
  }

  async ocrComplete(ocrAssetId) {
    return Promise.resolve(
      (this.ocr_complete = fetch(this.state.api_url + "ocrcomplete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Date": Date.now(),
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({
          user_id: this.state.user_id,
          pins_token: this.state.user_pins_token,
          requirements_id: this.state.requirements_id,
          asset_id: ocrAssetId
        })
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          if (data.status) {
            try {
              return data.data;
            } catch (e) {
              return false;
            }
          } else {
            return false;
          }
        }))
    );
  }

  async getDocumentCategory() {
    return Promise.resolve(
      (this.docCategory = fetch(this.state.api_url + "getcategory-doc", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Date": Date.now(),
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({
          user_id: this.state.user_id,
          insured_id: this.state.insured_id,
          requirements_id: this.state.requirements_id,
          pins_token: this.state.user_pins_token
        })
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          if (data.status) {
            return Object.values(data.data);
          } else {
            return [];
          }
        }))
    );
  }

  async getInsuredPolicies() {
    return Promise.resolve(
      (this.insuredPolicies = fetch(this.state.api_url + "getpolicies", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Date": Date.now(),
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({
          user_id: this.state.user_id,
          insured_id: this.state.insured_id,
          requirements_id: this.state.requirements_id,
          pins_token: this.state.user_pins_token
        })
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          if (data.status) {
            //console.log(data.doc);
            return Object.values(data.data);
          } else {
            return [];
          }
        }))
    );
  }

  async uploadedDocumentsService(formData) {
    formData.append("insured_id", this.state.insured_id);
    formData.append("requirements_id", this.state.requirements_id);
    let response = fetch(this.state.api_url + "upload-doc", {
      method: "POST",
      headers: {
        "Date": Date.now(),
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      body: formData
    }).then(function(response) {
      return response.json();
    });

    return response;
  }

  async deleteUploadedDocument(formData) {
    formData.insured_id = this.state.insured_id;
    formData.requirements_id = this.state.requirements_id;
    let response = fetch(this.state.api_url + "deldocument", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Date": Date.now(),
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      body: JSON.stringify(formData)
    }).then(function(response) {
      return response.json();
    });

    return response;
  }

  async updateuploadedDocument(formData) {
    let response = fetch(this.state.api_url + "updatedoc-category", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Date": Date.now(),
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      body: formData
    }).then(function(response) {
      return response.json();
    });

    return response;
  }

  async submitPolicyFormService(formData, asset_id = this.state.OcrAssetId) {
    console.log('spfs asset_id');
    console.log(asset_id);
    let policiesData = { ...formData };
    let datanew = JSON.stringify({
      insured_id: this.state.insured_id,
      requirements_id: this.state.requirements_id,
      user_id: this.state.user_id,
      pins_token: this.state.user_pins_token,
      policiesData: policiesData,
      asset_id: asset_id,
      state: this.state,
      test: 'test'
    });

    let response = fetch(this.state.api_url + "submitPolicyData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Date": Date.now(),
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      body: datanew
    }).then(function(response) {
      return response.json();
    });

    return response;
  }

  async updateUserModalPopup(data) {
    return Promise.resolve(
      (this.uploadedDocuments = fetch(this.state.api_url + "update-model", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Date": Date.now(),
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({ models: data, user_id: this.state.user_id })
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          return data;
        }))
    );
  }

  async getProcessedOcrData() {
    return Promise.resolve(
      (this.ocrData = fetch(this.state.api_url + "getprocessedocr", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Date": Date.now(),
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({
          user_id: this.state.user_id,
          pins_token: this.state.user_pins_token,
          requirements_id: this.state.requirements_id,
          asset_id: this.state.OcrAssetId
        })
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          if (data.status) {
            let res = JSON.parse(data.data);
            return res;
          } else {
            return [];
          }
        }))
    );
  }
}

export default Uploadservice;
