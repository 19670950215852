import React from "react";
import Modal from "react-bootstrap/Modal";
import loading from "./../assets/images/loading.gif";

export default function LoadingModal({ isLoadingModal }) {
  return (
    <Modal
      show={isLoadingModal}
      dialogClassName={"modal-dialog-centered customModal green"}
    >
      <Modal.Body>
        <img src={loading} alt="loading" />

        <small>
          {" "}
          Please do not close this window while PINS processes your documents.
          This could take up to a time
        </small>
      </Modal.Body>
    </Modal>
  );
}
