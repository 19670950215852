import React, { Component } from "react";
import Home from "./components/Home";
import Policies from "./components/Policies";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./assets/css/style.css";
import rollingspinner from "./assets/images/rolling_spinner.gif";
import { connect } from "react-redux";
import { userPopupModalsObject } from "./actions/actions";
//import { getUrlParam } from "./helper/helper";

class App extends Component {
  constructor(props) {
    super(props);
    //getUrlParam();
    this.props.changeUserModalBox();
  }

  render() {
    return Object.entries(this.props.userPopupModals).length > 0 ? (
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/policies" component={Policies} />
      </Router>
    ) : (
      <div className="loading-div">
        {" "}
        <img src={rollingspinner} alt="loading" />{" "}
      </div>
    );
  }
}

const MapStateToProps = state => {
  return {
    userPopupModals: state.userPopupModals
  };
};

const MapDispatchToProps = dispatch => {
  return {
    changeUserModalBox: () => {
      dispatch(userPopupModalsObject());
    }
  };
};

export default connect(MapStateToProps, MapDispatchToProps)(App);
