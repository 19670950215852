import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import icon8 from "./../assets/images/icon8.png";
import icon9 from "./../assets/images/icon9.png";

//export default function SkipPolicieModal({ isSkipPolicieModal , hideSkipPolicieModal ,continueSkipPolicieModal }) {

export class SkipPolicieModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHide: false
    };
  }

  handleChange = e => {
    this.setState({ isHide: this.refs.check_me.checked });
  };

  render() {
    // console.log(this.props.policy_type_skip);
    return (
      <Modal
        show={this.props.isSkipPolicieModal}
        onHide={this.props.hideSkipPolicieModal}
        dialogClassName={"modal-dialog-centered customModal blue"}
      >
        <Modal.Header>
          <img src={icon8} alt="icon8" />
          <button
            type="button"
            className="close"
            onClick={this.props.hideSkipPolicieModal}
          >
            <img src={icon9} alt="icon9" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <h4>Skip This Policy?</h4>

          {//Check if message failed
          this.props.policy_type_skip === "" ? (
            <>
              <p>One or more of the felds for this policy contain data.</p>
              <small>
                Skipping this policy will result in the data being removed and
                the policy will be considered not provided.
              </small>
            </>
          ) : (
            <>
              <p>The following policy will be considered not provided</p>{" "}
              <ul>
                <li key={this.props.policy_type_skip}>
                  {this.props.policy_type_skip}
                </li>
              </ul>
            </>
          )}

          <div className="btn-box">
            <button
              type="button"
              className="btn btn-bordered"
              onClick={this.props.hideSkipPolicieModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-ternary"
              onClick={this.props.continueSkipPolicieModal}
            >
              Continue
            </button>
          </div>
          <label className="checkbox-container">
            Don’t Remind Me Again
            <input
              type="checkbox"
              name="skipPolicyModalCheckbox"
              ref="check_me"
              onChange={this.handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SkipPolicieModal;
