// export const userPopupModalsObject = userPopupModals => {
//   return { type: "CHANGE_VALUE_MODAL", payload: userPopupModals };
// };.
import { Uploadservice } from "./../services/Uploadservice";

const uploadservice = new Uploadservice();

export const userPopupModalsObject = () => {
  //localStorage.removeItem("token");
  //localStorage.removeItem("storeObj");
  return async dispatch => {
    await uploadservice.getLogin().then(res => {
      if (res.status) {
        localStorage.setItem("token", res.data.token);
        dispatch({
          type: "CHANGE_VALUE_MODAL",
          payload: res.data.models
        });
      } else {
        window.parent.postMessage(
          "closeIframeLoginError",
          "*"
        );
      }
    });
  };
};

export const changeDocumentCategoryMiddelware = () => {
  return async dispatch => {
    await uploadservice.getDocumentCategory().then(res => {
      dispatch({
        type: "CHANGE_DOCUMENT_CATEGORY",
        payload: res
      });
    });
  };
};
