import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RemovePolicyModal from "./../common/RemovePolicyModal";
import {
  Tabs,
  TabList,
  Tab,
  PanelList,
  Panel,
  ExtraButton
} from "react-tabtab";
import Plus from "react-icons/lib/fa/plus";
import * as customStyle from "react-tabtab/lib/themes/bootstrap";
//import { AutomobileFormField } from "../fields";
import { notEmpty } from "./../helper/helper";

export class AutomobileLiabilityFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cats: this.props.formFilelds,
      errors: {},
      errorstop: [],
      tabs: [],
      activeIndex: 0,
      isOpen: false,
      policyIndex: 0,
      policy_type: this.props.policy_type,
      ocr_policies: this.props.ocr_policies || []
    };
    // console.log(props);
  }

  getTitle() {
    return "Automobile Liability";
  }

  successnotify = message => toast.success(message);
  errornotify = message => toast.error(message);

  showPolicyModal = index => {
    this.setState({ isOpen: true, policyIndex: index });
  };

  hidePolicyModal = () => {
    this.setState({ isOpen: false });
  };

  handleClickRemovePolicy = index => {
    this.setState(state => {
      let { cats, activeIndex } = state;
      cats = [...cats.slice(0, index), ...cats.slice(index + 1)];

      if (index - 1 >= 0) {
        activeIndex = index - 1;
      } else {
        activeIndex = 0;
      }

      return { cats, activeIndex };
    });

    // this.successnotify("Policy successfully removed.");

    this.hidePolicyModal();
  };

  handleChangeEffectiveDate = (date, idx) => {
    var d = date;
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var expDate = new Date(year + 1, month, day);

    let cats = [...this.state.cats];
    cats[idx]["effective"] = date;
    cats[idx]["policy_exp_date"] = expDate;
    this.setState({ cats });

    if (
      this.state.errorstop[idx] &&
      this.state.errorstop[idx]["effective"] !== undefined
    ) {
      delete this.state.errorstop[idx]["effective"];
      delete this.state.errorstop[idx]["policy_exp_date"];
      this.setState(this.state.errorstop);
    }
  };

  handleChangeExpirationDate = (date, idx) => {
    let cats = [...this.state.cats];
    cats[idx]["policy_exp_date"] = date;
    this.setState({ cats });

    if (
      this.state.errorstop[idx] &&
      this.state.errorstop[idx]["policy_exp_date"] !== undefined
    ) {
      delete this.state.errorstop[idx]["policy_exp_date"];
      this.setState(this.state.errorstop);
    }
  };

  handleChange = e => {
    let cats = [...this.state.cats];
    let placeholder = e.target.placeholder;
    if (placeholder === "$") {
      e.target.value = e.target.value.replace("$", "");
    }

    let limits = e.target.getAttribute("data-limits");
    if (limits === "true")
      cats[e.target.dataset.id].limits[e.target.name] = e.target.value;
    else cats[e.target.dataset.id][e.target.name] = e.target.value;

    this.setState({ cats, ocr_policies: {} });

    let idx = e.target.getAttribute("data-id");
    if (
      this.state.errorstop[idx] &&
      this.state.errorstop[idx][e.target.name] !== undefined &&
      e.target.value !== ""
    ) {
      if (limits === "true") {
        delete this.state.errorstop[idx]["each"];
        delete this.state.errorstop[idx]["body_person"];
        delete this.state.errorstop[idx]["body_accident"];
        delete this.state.errorstop[idx]["property"];
      } else {
        delete this.state.errorstop[idx][e.target.name];
      }
      this.setState(this.state.errorstop);
    }
  };

  handleChecked = e => {
    let cats = [...this.state.cats];
    cats[e.target.id][e.target.name] = e.target.value;
    this.setState({ cats });
  };

  validateForm = async () => {
    //set error to blank in state

    await this.setState({
      errors: {}
    });

    //check for state value
    let fields = [
      "insuranceCompany",
      "policy_exp_date",
      "effective",
      "policy_number"
    ];

    this.state.cats.map(async (val, idx) => {
      let tempErrors = {};

      for (const field of fields) {
        if (!notEmpty(val[field])) {
          tempErrors[field] = "This field is required";

          await this.setState(
            {
              errors: { ...tempErrors }
            },
            () => {
              this.state.errorstop[idx] = this.state.errors;
              this.forceUpdate();
            }
          );
        } else {
          if (this.state.errorstop[idx]) {
            this.state.errorstop[idx][field] = tempErrors[field];
            this.forceUpdate();
          }
        }
      }

      if (
        !notEmpty(val.limits["each"]) &&
        !notEmpty(val.limits["body_person"]) &&
        !notEmpty(val.limits["body_accident"]) &&
        !notEmpty(val.limits["property"])
      ) {
        tempErrors["body_person"] = "This field is required";
        tempErrors["body_accident"] = "This field is required";
        tempErrors["property"] = "This field is required";
        await this.setState(
          {
            errors: { ...tempErrors }
          },
          () => {
            this.state.errorstop[idx] = this.state.errors;
            this.forceUpdate();
          }
        );

        let temp = this.state.errors ? { ...this.state.errors } : {};
        temp["each"] = "This field is required";

        if (Object.keys(this.state.errorstop).length > 0) {
          console.log("a");
          let temperrorStop = { ...this.state.errorstop };
          if (temperrorStop[idx]) {
            temperrorStop[idx]["each"] = "This field is required";
          } else {
            temperrorStop[idx] = {};
            temperrorStop[idx]["each"] = "This field is required";
          }

          await this.setState({
            errors: temp,
            errorstop: temperrorStop
          });
        } else {
          console.log("b");
          let temperrorStop = {};
          temperrorStop[idx] = {};
          temperrorStop[idx]["each"] = "This field is required";

          await this.setState({
            errors: temp,
            errorstop: temperrorStop
          });
        }
      } else {
        let temp = { ...this.state.errors };
        if (temp["each"]) delete temp["each"];

        let temperrorStop = { ...this.state.errorstop };
        if (temperrorStop[idx] && temperrorStop[idx]["each"])
          delete temperrorStop[idx]["each"];

        await this.setState({
          errors: temp,
          errorstop: temperrorStop
        });
      }
    });

    if (Object.keys(this.state.errors).length > 0) return false;
    else return true;
  };

  async getFormValue() {
    //check for form validation
    if (await this.validateForm()) {
      return true;
    } else {
      return false;
    }
  }

  randerError = (fieldName, idx) => {
    if (
      this.state.errorstop[idx] &&
      this.state.errorstop[idx][fieldName] &&
      this.state.errorstop[idx][fieldName] !== ""
    )
      return "custom-error";
    // return this.state.errorstop[idx][fieldName];

    return "";
  };

  randerErrorLimit = (fieldName, idx) => {
    if (
      this.state.errorstop[idx] &&
      this.state.errorstop[idx][fieldName] &&
      this.state.errorstop[idx][fieldName] !== ""
    )
      return "custom-error-limit";
    return "";
  };

  handleTabChange = index => {
    this.setState({ activeIndex: index });
  };

  handleEdit = ({ type, index }) => {
    this.setState(state => {
      let { cats, activeIndex } = state;
      if (type === "delete") {
        var values = Object.values(cats[index]);

        if (
          values.every(
            element =>
              (typeof element === "string" && element === "") ||
              typeof element === "number"
          )
        ) {
          cats = [...cats.slice(0, index), ...cats.slice(index + 1)];

          if (index - 1 >= 0) {
            activeIndex = index - 1;
          } else {
            activeIndex = 0;
          }

          this.successnotify("Policy successfully removed.");
        } else {
          this.showPolicyModal(index);
        }
      }

      return { cats, activeIndex };
    });
  };

  handleExtraButton = () => {
    this.setState(prevState => ({
      cats: [
        ...prevState.cats,
        {
          company: "",
          insuranceCompany: "",
          policy_number: "",
          effective: "",
          policy_exp_date: "",
          non_owned: "",
          hired: "",
          scheduled: "",
          owned: "",
          liability: "",
          limits: {
            each: "",
            body_accident: "",
            body_person: "",
            property: ""
          }
        }
      ],
      activeIndex: this.state.cats.length
    }));
  };

  async componentDidMount() {
    let objLength = this.state.ocr_policies.length;
    if (objLength > 1) {
      for (let i = 1; i < this.state.ocr_policies.length; i++) {
        await this.handleExtraButton();
      }
    }
  }

  render() {
    const { activeIndex, cats, ocr_policies } = this.state;
    const tabTemplate = [];
    const panelTemplate = [];

    cats.map((val, idx) => {
      let ocr = ocr_policies[idx] || { limits: [] };
      if (ocr.limits === undefined) {
        ocr.limits = cats[idx].limits;
      }
      const closable = cats.length > 1 && idx === activeIndex;
      tabTemplate.push(
        <Tab key={idx} closable={closable}>
          Policy {idx + 1}
        </Tab>
      );
      return panelTemplate.push(
        <Panel key={idx}>
          <div className="formStepsIn" key={idx}>
            <input
              type="hidden"
              value={
                (cats[idx].company = cats[idx].company || ocr.company || "")
              }
              data-id={idx}
              name="company"
            />
            <div className="row">
              <div className="col-md-7">
                <div className="form-group">
                  <label>
                    Insurance Company<span>*</span>
                  </label>
                  <input
                    type="text"
                    value={
                      (cats[idx].insuranceCompany =
                        cats[idx].insuranceCompany || ocr.company_name || "")
                    }
                    data-id={idx}
                    placeholder="Insurance Company"
                    name="insuranceCompany"
                    className={
                      "form-control " +
                      this.randerError("insuranceCompany", idx)
                    }
                    onChange={this.handleChange}
                  />
                  {/*<span className="text-danger">{this.randerError('insuranceCompany',idx)}</span>*/}
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group">
                  <label>Combined Single Limit (ea accident)</label>
                  <NumberFormat
                    thousandSeparator={true}
                    prefix={"$"}
                    allowNegative={false}
                    value={
                      (cats[idx].limits.each =
                        cats[idx].limits.each || ocr.limits.each || "")
                    }
                    data-id={idx}
                    data-limits={true}
                    placeholder="$"
                    name="each"
                    className={
                      "form-control " + this.randerErrorLimit("each", idx)
                    }
                    onChange={this.handleChange}
                  />
                  {/*<span className="text-danger">{this.randerError('each',idx)}</span>*/}
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-group">
                  <label>
                    Policy Number<span>*</span>
                  </label>
                  <input
                    type="text"
                    value={
                      (cats[idx].policy_number =
                        cats[idx].policy_number || ocr.policy_num || "")
                    }
                    data-id={idx}
                    placeholder="0987654321"
                    name="policy_number"
                    className={
                      "form-control " + this.randerError("policy_number", idx)
                    }
                    onChange={this.handleChange}
                  />
                  {/*<span className="text-danger">{this.randerError('policy_number',idx)}</span>*/}
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group">
                  <label>Bodily Injury (per person)</label>
                  <NumberFormat
                    thousandSeparator={true}
                    prefix={"$"}
                    allowNegative={false}
                    value={
                      (cats[idx].limits.body_person =
                        cats[idx].limits.body_person ||
                        ocr.limits.body_person ||
                        "")
                    }
                    data-id={idx}
                    placeholder="$"
                    data-limits={true}
                    name="body_person"
                    className={
                      "form-control " +
                      this.randerErrorLimit("body_person", idx)
                    }
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Effective Date<span>*</span>
                      </label>
                      <div className="iconbox">
                        {/*console.log(effective)*/}

                        <DatePicker
                          name="effective"
                          className={
                            "form-control " + this.randerError("effective", idx)
                          }
                          placeholderText="MM/DD/YYYY"
                          onKeyDown={e => e.preventDefault()}
                          selected={
                            (cats[idx].effective =
                              cats[idx].effective || ocr.effective === undefined
                                ? cats[idx].effective
                                : new Date(ocr.effective))
                          }
                          onChange={date =>
                            this.handleChangeEffectiveDate(date, idx)
                          }
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          data-id={idx}
                        />
                        {/*<span className="text-danger">{this.randerError('effective',idx)}</span>*/}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Expiration Date<span>*</span>
                      </label>
                      <div className="iconbox">
                        {/*console.log(policy_exp_date)*/}

                        <DatePicker
                          name="policy_exp_date"
                          className={
                            "form-control " +
                            this.randerError("policy_exp_date", idx)
                          }
                          placeholderText="MM/DD/YYYY"
                          onKeyDown={e => e.preventDefault()}
                          selected={
                            (cats[idx].policy_exp_date =
                              cats[idx].policy_exp_date ||
                              ocr.exp_date === undefined
                                ? cats[idx].policy_exp_date
                                : new Date(ocr.exp_date))
                          }
                          onChange={date =>
                            this.handleChangeExpirationDate(date, idx)
                          }
                          minDate={
                            cats[idx].effective === undefined
                              ? new Date()
                              : cats[idx].effective
                          }
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          data-id={idx}
                        />
                        {/*<span className="text-danger">{this.randerError('policy_exp_date',idx)}</span>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="form-group">
                  <label>Bodily Injury (per accident)</label>
                  <NumberFormat
                    thousandSeparator={true}
                    prefix={"$"}
                    allowNegative={false}
                    value={
                      (cats[idx].limits.body_accident =
                        cats[idx].limits.body_accident ||
                        ocr.limits.body_accident ||
                        "")
                    }
                    data-id={idx}
                    placeholder="$"
                    data-limits={true}
                    name="body_accident"
                    className={
                      "form-control " +
                      this.randerErrorLimit("body_accident", idx)
                    }
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="col-lg-7">
                <div className="form-group">
                  <label className="checkbox-container">
                    Any Autos
                    <input
                      type="checkbox"
                      name="liability"
                      defaultChecked={
                        (cats[idx].liability =
                          cats[idx].liability || ocr.liability || "")
                      }
                      onChange={e => {
                        this.handleChecked({
                          target: {
                            name: e.target.name,
                            value: e.target.checked ? "on" : "",
                            id: idx
                          }
                        });
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <br />
                  <label className="checkbox-container">
                    All Owned Autos
                    <input
                      type="checkbox"
                      name="owned"
                      defaultChecked={
                        (cats[idx].owned = cats[idx].owned || ocr.owned || "")
                      }
                      onChange={e => {
                        this.handleChecked({
                          target: {
                            name: e.target.name,
                            value: e.target.checked ? "on" : "",
                            id: idx
                          }
                        });
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <br />
                  <label className="checkbox-container">
                    Scheduled Autos
                    <input
                      type="checkbox"
                      name="scheduled"
                      defaultChecked={
                        (cats[idx].scheduled =
                          cats[idx].scheduled || ocr.scheduled || "")
                      }
                      onChange={e => {
                        this.handleChecked({
                          target: {
                            name: e.target.name,
                            value: e.target.checked ? "on" : "",
                            id: idx
                          }
                        });
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <br />
                  <label className="checkbox-container">
                    Hired Autos
                    <input
                      type="checkbox"
                      name="hired"
                      defaultChecked={
                        (cats[idx].hired = cats[idx].hired || ocr.hired || "")
                      }
                      onChange={e => {
                        this.handleChecked({
                          target: {
                            name: e.target.name,
                            value: e.target.checked ? "on" : "",
                            id: idx
                          }
                        });
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <br />
                  <label className="checkbox-container">
                    Non Owned Autos
                    <input
                      type="checkbox"
                      name="non_owned"
                      defaultChecked={
                        (cats[idx].non_owned =
                          cats[idx].non_owned || ocr.non_owned || "")
                      }
                      onChange={e => {
                        this.handleChecked({
                          target: {
                            name: e.target.name,
                            value: e.target.checked ? "on" : "",
                            id: idx
                          }
                        });
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="form-group">
                  <label>Property Damage (per accident)</label>
                  <NumberFormat
                    thousandSeparator={true}
                    prefix={"$"}
                    allowNegative={false}
                    value={
                      (cats[idx].limits.property =
                        cats[idx].limits.property || ocr.limits.property || "")
                    }
                    data-id={idx}
                    placeholder="$"
                    data-limits={true}
                    name="property"
                    className={
                      "form-control " + this.randerErrorLimit("property", idx)
                    }
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>{" "}
        </Panel>
      );
    });

    return (
      <React.Fragment>
        <div className="step1 mobile">
          <span>1. Upload Documents</span>
        </div>
        <div className="form-box">
          <h3>{this.getTitle()}</h3>
          <ul>
            {this.props.insuredPolicies.map(item => (
              <li
                key={item.policy_id}
                className={item.policy_type === "auto" ? "active" : ""}
              >
                <span></span>
              </li>
            ))}
          </ul>
        </div>
        <div className="form-box-1">
          <div className="formSteps active">
            <div className="tabsDiv">
              <Tabs
                onTabEdit={this.handleEdit}
                onTabChange={this.handleTabChange}
                activeIndex={activeIndex}
                customStyle={customStyle}
                ExtraButton={
                  <ExtraButton onClick={this.handleExtraButton}>
                    <Plus />
                  </ExtraButton>
                }
                showModalButton={false}
              >
                <TabList>{tabTemplate}</TabList>
                <PanelList>{panelTemplate}</PanelList>
              </Tabs>
            </div>
          </div>
        </div>

        <RemovePolicyModal
          state={this.state}
          hidePolicyModal={this.hidePolicyModal}
          handleClickRemovePolicy={this.handleClickRemovePolicy}
        />

        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default AutomobileLiabilityFrom;
