import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RemovePolicyModal from "./../common/RemovePolicyModal";

import {
  Tabs,
  TabList,
  Tab,
  PanelList,
  Panel,
  ExtraButton
} from "react-tabtab";
import Plus from "react-icons/lib/fa/plus";
//import {makeData} from '../utils';
import * as customStyle from "react-tabtab/lib/themes/bootstrap";
//import { OcipFormField } from "../fields";
import { notEmpty } from "./../helper/helper";

export class OcipForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cats: this.props.formFilelds,
      errors: {},
      errorstop: [],
      tabs: [],
      activeIndex: 0,
      isOpen: false,
      policyIndex: 0,
      policy_type: this.props.policy_type,
      ocr_policies: this.props.ocr_policies || []
    };
  }

  successnotify = message => toast.success(message);
  errornotify = message => toast.error(message);

  showPolicyModal = index => {
    this.setState({ isOpen: true, policyIndex: index });
  };

  hidePolicyModal = () => {
    this.setState({ isOpen: false });
  };

  handleClickRemovePolicy = index => {
    this.setState(state => {
      let { cats, activeIndex } = state;
      cats = [...cats.slice(0, index), ...cats.slice(index + 1)];

      if (index - 1 >= 0) {
        activeIndex = index - 1;
      } else {
        activeIndex = 0;
      }

      return { cats, activeIndex };
    });

    //this.successnotify("Policy successfully removed.");

    this.hidePolicyModal();
  };

  handleChangeEffectiveDate = (date, idx) => {
    var d = date;
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var expDate = new Date(year + 1, month, day);

    let cats = [...this.state.cats];
    cats[idx]["effective"] = date;
    cats[idx]["policy_exp_date"] = expDate;
    this.setState({ cats });

    if (
      this.state.errorstop[idx] &&
      this.state.errorstop[idx]["effective"] !== undefined
    ) {
      delete this.state.errorstop[idx]["effective"];
      delete this.state.errorstop[idx]["policy_exp_date"];
      this.setState(this.state.errorstop);
    }
  };

  handleChangeExpirationDate = (date, idx) => {
    let cats = [...this.state.cats];
    cats[idx]["policy_exp_date"] = date;
    this.setState({ cats });

    if (
      this.state.errorstop[idx] &&
      this.state.errorstop[idx]["policy_exp_date"] !== undefined
    ) {
      delete this.state.errorstop[idx]["policy_exp_date"];
      this.setState(this.state.errorstop);
    }
  };

  handleChange = e => {
    let cats = [...this.state.cats];
    let placeholder = e.target.placeholder;
    if (placeholder === "$") {
      e.target.value = e.target.value.replace("$", "");
    }
    cats[e.target.dataset.id][e.target.name] = e.target.value;
    this.setState({ cats, ocr_policies: {} });

    let idx = e.target.getAttribute("data-id");
    if (
      this.state.errorstop[idx] &&
      this.state.errorstop[idx][e.target.name] !== undefined &&
      e.target.value !== ""
    ) {
      delete this.state.errorstop[idx][e.target.name];
      this.setState(this.state.errorstop);
    }
  };

  validateForm = async () => {
    //set error to blank in state

    await this.setState({
      errors: {}
    });

    //check for state value
    let fields = [
      "insuranceCompany",
      "policy_exp_date",
      "effective",
      "policy_number",
      "limit_amt"
    ];

    this.state.cats.map(async (val, idx) => {
      let tempErrors = {};

      for (const field of fields) {
        if (!notEmpty(val[field])) {
          tempErrors[field] = "This field is invalid";

          await this.setState(
            {
              errors: { ...tempErrors }
            },
            () => {
              this.state.errorstop[idx] = this.state.errors;
              this.forceUpdate();
            }
          );
        } else {
          if (this.state.errorstop[idx]) {
            this.state.errorstop[idx][field] = tempErrors[field];
            this.forceUpdate();
          }
        }
      }
    });

    if (Object.keys(this.state.errors).length > 0) return false;
    else return true;
  };
  getTitle() {
    return "OCIP";
  }

  async getFormValue() {
    //check for form validation
    if (await this.validateForm()) {
      return true;
    } else {
      return false;
    }
  }

  randerError = (fieldName, idx) => {
    if (
      this.state.errorstop[idx] &&
      this.state.errorstop[idx][fieldName] &&
      this.state.errorstop[idx][fieldName] !== ""
    )
      return "custom-error";
    //return this.state.errorstop[idx][fieldName];

    return "";
  };

  handleTabChange = index => {
    this.setState({ activeIndex: index });
  };

  handleEdit = ({ type, index }) => {
    this.setState(state => {
      let { cats, activeIndex } = state;
      if (type === "delete") {
        var values = Object.values(cats[index]);

        if (
          values.every(
            element =>
              (typeof element === "string" && element === "") ||
              typeof element === "number"
          )
        ) {
          cats = [...cats.slice(0, index), ...cats.slice(index + 1)];

          if (index - 1 >= 0) {
            activeIndex = index - 1;
          } else {
            activeIndex = 0;
          }

          this.successnotify("Policy successfully removed.");
        } else {
          this.showPolicyModal(index);
        }
      }

      return { cats, activeIndex };
    });
  };

  handleExtraButton = () => {
    this.setState(prevState => ({
      cats: [
        ...prevState.cats,
        {
          company: "",
          insuranceCompany: "",
          policy_number: "",
          effective: "",
          policy_exp_date: "",
          limit_amt: "",
          type: ""
        }
      ],
      activeIndex: this.state.cats.length
    }));
  };

  async componentDidMount() {
    let objLength = this.state.ocr_policies.length;
    if (objLength > 1) {
      for (let i = 1; i < this.state.ocr_policies.length; i++) {
        await this.handleExtraButton();
      }
    }
  }

  render() {
    const { activeIndex, cats, ocr_policies } = this.state;
    const tabTemplate = [];
    const panelTemplate = [];

    cats.map((val, idx) => {
      let ocr = ocr_policies[idx] || { limits: [] };
      const closable = cats.length > 1 && idx === activeIndex;
      tabTemplate.push(
        <Tab key={idx} closable={closable}>
          Policy {idx + 1}
        </Tab>
      );
      return panelTemplate.push(
        <Panel key={idx}>
          <div className="formStepsIn" key={idx}>
            <input
              type="hidden"
              value={
                (cats[idx].company = cats[idx].company || ocr.company || "")
              }
              data-id={idx}
              name="company"
            />
            <div className="row">
              <div className="col-md-7">
                <div className="form-group">
                  <label>
                    Insurance Company<span>*</span>
                  </label>

                  <input
                    type="text"
                    value={
                      (cats[idx].insuranceCompany =
                        cats[idx].insuranceCompany || ocr.company_name || "")
                    }
                    data-id={idx}
                    placeholder="Insurance Company"
                    name="insuranceCompany"
                    className={
                      "form-control " +
                      this.randerError("insuranceCompany", idx)
                    }
                    onChange={this.handleChange}
                  />
                  {/*<span className="text-danger">{this.randerError('insuranceCompany',idx)}</span>*/}
                </div>
              </div>

              <div className="col-md-5">
                <div className="form-group">
                  <label>
                    Policy Number<span>*</span>
                  </label>
                  <input
                    type="text"
                    value={
                      (cats[idx].policy_number =
                        cats[idx].policy_number || ocr.policy_num || "")
                    }
                    data-id={idx}
                    placeholder="0987654321"
                    name="policy_number"
                    className={
                      "form-control " + this.randerError("policy_number", idx)
                    }
                    onChange={this.handleChange}
                  />

                  {/*<span className="text-danger">{this.randerError('policy_number',idx)}</span>*/}
                </div>
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Effective Date<span>*</span>
                      </label>
                      <div className="iconbox">
                        {/*console.log(effective)*/}

                        <DatePicker
                          name="effective"
                          className={
                            "form-control " + this.randerError("effective", idx)
                          }
                          placeholderText="MM/DD/YYYY"
                          onKeyDown={e => e.preventDefault()}
                          selected={
                            (cats[idx].effective =
                              cats[idx].effective || ocr.effective === undefined
                                ? cats[idx].effective
                                : new Date(ocr.effective))
                          }
                          onChange={date =>
                            this.handleChangeEffectiveDate(date, idx)
                          }
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          data-id={idx}
                        />

                        {/*<span className="text-danger">{this.randerError('effective',idx)}</span>*/}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Expiration Date<span>*</span>
                      </label>
                      <div className="iconbox">
                        {/*console.log(policy_exp_date)*/}

                        <DatePicker
                          name="policy_exp_date"
                          className={
                            "form-control " +
                            this.randerError("policy_exp_date", idx)
                          }
                          placeholderText="MM/DD/YYYY"
                          onKeyDown={e => e.preventDefault()}
                          selected={
                            (cats[idx].policy_exp_date =
                              cats[idx].policy_exp_date ||
                              ocr.exp_date === undefined
                                ? cats[idx].policy_exp_date
                                : new Date(ocr.exp_date))
                          }
                          onChange={date =>
                            this.handleChangeExpirationDate(date, idx)
                          }
                          minDate={
                            cats[idx].effective === undefined
                              ? new Date()
                              : cats[idx].effective
                          }
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          data-id={idx}
                        />

                        {/*<span className="text-danger">{this.randerError('policy_exp_date',idx)}</span>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div className="form-group">
                  <label>
                    {" "}
                    Policy Limit<span>*</span>
                  </label>
                  <NumberFormat
                    thousandSeparator={true}
                    prefix={"$"}
                    allowNegative={false}
                    value={
                      (cats[idx].limit_amt =
                        cats[idx].limit_amt || ocr.limit_amt || "")
                    }
                    data-id={idx}
                    placeholder="$"
                    name="limit_amt"
                    className={
                      "form-control " + this.randerError("limit_amt", idx)
                    }
                    onChange={this.handleChange}
                  />
                  {/*<span className="text-danger">{this.randerError('limit_amt',idx)}</span>*/}
                </div>
              </div>

              <div className="col-lg-7">
                <div className="form-group">
                  <label>Policy Type</label>
                  <input
                    type="text"
                    value={cats[idx].type || ""}
                    data-id={idx}
                    placeholder="Policy Type"
                    name="type"
                    className="form-control"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>{" "}
        </Panel>
      );
    });

    return (
      <React.Fragment>
        <div className="step1 mobile">
          <span>1. Upload Documents</span>
        </div>
        <div className="form-box">
          <h3>{this.getTitle()}</h3>
          <ul>
            {this.props.insuredPolicies.map(item => (
              <li
                key={item.policy_id}
                className={item.policy_type === "ocip" ? "active" : ""}
              >
                <span></span>
              </li>
            ))}
          </ul>
        </div>
        <div className="form-box-1">
          <div className="formSteps active">
            <div className="tabsDiv">
              <Tabs
                onTabEdit={this.handleEdit}
                onTabChange={this.handleTabChange}
                activeIndex={activeIndex}
                customStyle={customStyle}
                ExtraButton={
                  <ExtraButton onClick={this.handleExtraButton}>
                    <Plus />
                  </ExtraButton>
                }
                showModalButton={false}
              >
                <TabList>{tabTemplate}</TabList>
                <PanelList>{panelTemplate}</PanelList>
              </Tabs>
            </div>
          </div>
        </div>
        <RemovePolicyModal
          state={this.state}
          hidePolicyModal={this.hidePolicyModal}
          handleClickRemovePolicy={this.handleClickRemovePolicy}
        />
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default OcipForm;
