const istate = {
  isTabWorking: false,
  isWelcomeModal: false,
  userPopupModals: {},
  policyFormdata: {},
  OcrAssetId: ""
};

const reducer = (state = istate, action) => {
  if (action.type === "CHANGE_VALUE") {
    return {
      ...state,
      isTabWorking: action.payload
    };
  }

  if (action.type === "CHANGE_WELCOME_MODAL_VALUE") {
    return {
      ...state,
      isWelcomeModal: action.payload
    };
  }

  if (action.type === "CHANGE_VALUE_MODAL") {
    return {
      ...state,
      userPopupModals: action.payload
    };
  }

  if (action.type === "CHANGE_POLICY_FORM_DATA") {
    return {
      ...state,
      policyFormdata: action.payload
    };
  }

  if (action.type === "CHANGE_ORC_ASSETS_ID") {
    return {
      ...state,
      OcrAssetId: action.payload
    };
  }

  return state;
};

export default reducer;
