import React from "react";
import Modal from "react-bootstrap/Modal";
import close from "./../assets/images/close.png";
import icon9 from "./../assets/images/icon9.png";

export default function RemovePolicyModal({
  hidePolicyModal,
  handleClickRemovePolicy,
  state
}) {
  const { isOpen, policyIndex } = state;

  return (
    <Modal
      show={isOpen}
      onHide={hidePolicyModal}
      dialogClassName={"modal-dialog-centered customModal red"}
    >
      <Modal.Header>
        <img src={close} alt="close" />
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={hidePolicyModal}
        >
          <img src={icon9} alt="icon9" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <h4>Warning!</h4>
        <p></p>
        <p>Remove This Policy?</p>
        <p></p>
        <small>This policy has one or more fields populated with data.</small>
        <p></p>

        <div className="btn-box">
          <button
            type="button"
            className="btn btn-bordered"
            onClick={hidePolicyModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => handleClickRemovePolicy(policyIndex)}
          >
            Delete
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
