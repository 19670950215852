import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import icon8 from "./../assets/images/icon8.png";
import icon9 from "./../assets/images/icon9.png";

export class MissingDocModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHide: false
    };
  }

  handleChange = e => {
    this.setState({ isHide: this.refs.check_me.checked });
  };

  render() {
    return (
      <Modal
        show={this.props.state.isMissingCategoryModal}
        onHide={() => {
          this.props.missingDocModalClose();
        }}
        dialogClassName={"modal-dialog-centered customModal yellow"}
      >
        <Modal.Header>
          <img src={icon8} alt="icon8" />
          <button
            type="button"
            className="close"
            onClick={() => {
              this.props.missingDocModalClose();
            }}
          >
            <img src={icon9} alt="icon9" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <form id="missingDocModalForm">
            <h4>Continue With Missing Documents?</h4>
            <p>The following document(s) have not been submitted:</p>
            <ul>
              {this.props.state.documentCategories.map(item => {
                if (item.hasdoc && item.hasdoc.length === 0)
                  return <li key={item.doc_type}>{item.doc_name}</li>;
                else return "";
              })}
            </ul>
            <div className="btn-box">
              <button
                type="button"
                className="btn btn-bordered"
                onClick={() => {
                  this.props.missingDocModalClose();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-default yellow"
                onClick={() => {
                  this.props.missingDocModalContinue();
                }}
              >
                Continue
              </button>
            </div>
            <label className="checkbox-container">
              Don’t Remind Me Again
              <input
                type="checkbox"
                name="missingDocModalCheckbox"
                ref="check_me"
                onChange={this.handleChange}
              />
              <span className="checkmark"></span>
            </label>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default MissingDocModal;
