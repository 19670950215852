import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import icon10 from "./../assets/images/icon10.png";
import icon9 from "./../assets/images/icon9.png";

//export default function WelcomeModal({ isWelcomeModal , hideWelcomeModal }) {

export class WelcomeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHide: false
    };
  }

  handleChange = e => {
    this.setState({ isHide: this.refs.check_me.checked });
  };

  render() {
    var isModalOpen = false;

    if (
      this.props.isWelcomeModal === true &&
      this.props.isWelcomeModalUserTable === true
    ) {
      isModalOpen = true;
    }
    return (
      <Modal
        show={isModalOpen}
        dialogClassName={"modal-dialog-centered customModal green"}
      >
        <Modal.Header>
          <img src={icon10} alt="icon10" />
          <button
            type="button"
            className="close"
            onClick={this.props.hideWelcomeModal}
          >
            <img src={icon9} alt="icon9" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <h4>Documents Proccessed!</h4>
          <small>
            PINS has extracted data from your uploaded documents. The following
            forms have been automatically populated with your policy data.
          </small>
          <p>It is your responsibility to check the forms for accuracy.</p>
          <div className="btn-box">
            <button
              type="button"
              className="btn btn-default"
              onClick={this.props.continueWelcomeModal}
            >
              Continue
            </button>
          </div>
          <label className="checkbox-container">
            Don’t Remind Me Again
            <input
              type="checkbox"
              name="wellcomePolicyModalCheckbox"
              ref="check_me"
              onChange={this.handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </Modal.Body>
      </Modal>
    );
  }
}

export default WelcomeModal;
