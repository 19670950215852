import queryString from "query-string";

export function getUrlParam() {
  var query = queryString.parse(window.location.search);
  if (Object.keys(query).length > 0) {
    localStorage.setItem("storeObj", JSON.stringify(query));
  }
}

export function handleChange(e) {
  var query = queryString.parse(window.location.search);
  if (Object.keys(query).length > 0) {
    localStorage.setItem("storeObj", JSON.stringify(query));
  }
}

//Not empty string
export function notEmpty(string) {
  if (string && string.toString().trim().length > 0) return true;
  else return false;
}

export function getObject(insuredPolicies) {
  let tempobj = {};
  for (let i = 0; i < insuredPolicies.length; i++) {
    let policy_type = insuredPolicies[i].policy_type;

    switch (policy_type) {
      case "general": {
        tempobj[policy_type] = [
          {
            company: "",
            insuranceCompany: "",
            policy_number: "",
            effective: "",
            policy_exp_date: "",
            deductible_amt: "",
            retention_amt: "",
            comm_gen: "",
            claims: "",
            project: "",
            policy: "",
            occurrence: "",
            limits: {
              each: "",
              fire: "",
              medical: "",
              personal: "",
              aggregate: "",
              products: ""
            }
          }
        ];
        break;
      }
      case "auto": {
        tempobj[policy_type] = [
          {
            company: "",
            insuranceCompany: "",
            policy_number: "",
            effective: "",
            policy_exp_date: "",
            non_owned: "",
            hired: "",
            scheduled: "",
            owned: "",
            liability: "",
            limits: {
              each: "",
              body_accident: "",
              body_person: "",
              property: ""
            }
          }
        ];
        break;
      }
      case "excess": {
        tempobj[policy_type] = [
          {
            company: "",
            insuranceCompany: "",
            policy_number: "",
            occurence: "",
            claims: "",
            deductible: "",
            retention: "",
            effective: "",
            policy_exp_date: "",
            each_amt: "",
            aggregate_amt: "",
            deductible_amt: "",
            retention_amt: ""
          }
        ];
        break;
      }
      case "worker": {
        tempobj[policy_type] = [
          {
            company: "",
            insuranceCompany: "",
            policy_number: "",
            effective: "",
            policy_exp_date: "",
            limits: {
              each: "",
              disease_employee: "",
              disease_policy: ""
            }
          }
        ];
        break;
      }
      case "property": {
        tempobj[policy_type] = [
          {
            company: "",
            insuranceCompany: "",
            policy_number: "",
            effective: "",
            policy_exp_date: "",
            limit_amt: ""
          }
        ];
        break;
      }
      case "bond": {
        tempobj[policy_type] = [
          {
            company: "",
            policy_bond_policy: "",
            policy_bond_carrier: "",
            policy_bond_agent: "",
            limit_amt: ""
          }
        ];
        break;
      }
      case "pollution": {
        tempobj[policy_type] = [
          {
            company: "",
            insuranceCompany: "",
            policy_number: "",
            effective: "",
            policy_exp_date: "",
            limit_amt: ""
          }
        ];
        break;
      }
      case "liability": {
        tempobj[policy_type] = [
          {
            company: "",
            insuranceCompany: "",
            policy_number: "",
            effective: "",
            policy_exp_date: "",
            limit_amt: ""
          }
        ];
        break;
      }
      case "aviation": {
        tempobj[policy_type] = [
          {
            company: "",
            insuranceCompany: "",
            policy_number: "",
            effective: "",
            policy_exp_date: "",
            limit_amt: ""
          }
        ];
        break;
      }
      case "ocip": {
        tempobj[policy_type] = [
          {
            company: "",
            insuranceCompany: "",
            policy_number: "",
            effective: "",
            policy_exp_date: "",
            limit_amt: "",
            type: ""
          }
        ];
        break;
      }
      case "ccip": {
        tempobj[policy_type] = [
          {
            company: "",
            insuranceCompany: "",
            policy_number: "",
            effective: "",
            policy_exp_date: "",
            limit_amt: "",
            type: ""
          }
        ];
        break;
      }
      default: {
        tempobj[policy_type] = [
          {
            insuranceCompany: "",
            policy_number: "",
            effective: "",
            policy_exp_date: "",
            limit_amt: "",
            type: "",
            name: ""
          }
        ];
        break;
      }
    }
  }

  return tempobj;
}
