import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import warning from "./../assets/images/warning.png";
import icon9 from "./../assets/images/icon9.png";

export default class DeleteDocumentModal extends Component {
  checkedCats = e => {
    if (this.props.showDocCategories().includes(e) === true) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const {
      uploadeddocuments,
      isDeleteDocumentModal,
      displayDocId
    } = this.props.state;

    return (
      <Modal
        show={isDeleteDocumentModal}
        onHide={this.props.hideDeleteDocumentModal}
        dialogClassName={"modal-dialog-centered customModal red"}
      >
        <Modal.Header>
          <img src={warning} alt="warning" />
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.hideDeleteDocumentModal}
          >
            <img src={icon9} alt="icon9" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <h4>Warning!</h4>
          <p>Are you sure you want to delete?</p>
          <p></p>
          <ul>
            {uploadeddocuments.map(uploadeditem => {
              if (uploadeditem.doc_id === displayDocId)
                return (
                  <li key={uploadeditem.originalname}>
                    {uploadeditem.originalname}
                  </li>
                );
              else return "";
            })}
          </ul>
          <p></p>
          <small>
            This document has been uploaded for the following requirement(s):
          </small>
          <p></p>
          <ul>
            {this.props.state.documentCategories.map(item =>
              this.checkedCats(item.doc_type) ? (
                <li key={item.doc_type}> {item.doc_name} </li>
              ) : (
                ""
              )
            )}
          </ul>
          <div className="btn-box">
            <button
              type="button"
              className="btn btn-bordered"
              onClick={this.props.hideDeleteDocumentModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => this.props.handleClickDelete(displayDocId)}
            >
              Continue
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
