import React, { Component } from "react";
import icon1 from "./../assets/images/icon1.png";
import icon2 from "./../assets/images/icon2.png";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Uploadservice } from "./../services/Uploadservice";
import WelcomeModal from "./../common/WelcomeModal";
import ExitWarningModal from "./../common/ExitWarningModal";
import SkipPolicieModal from "./../common/SkipPolicieModal";
import LoadingModal from "./../common/LoadingModal";
import { getObject } from "./../helper/helper";

import {
  GeneralLiabilityForm,
  ExcessForm,
  AutomobileLiabilityFrom,
  WorkersCompensationForm,
  PollutionForm,
  AviationForm,
  PropertyForm,
  LiabilityForm,
  OcipForm,
  CcipForm,
  BondForm,
  OtherForm
} from "../Forms";

/*import {
  GeneralFormField,
  AutomobileFormField,
  WorkerFormField,
  PollutionFormField,
  AviationFormField,
  LiabilityFormField,
  PropertyFormField,
  BondFormField,
  ExcessFormField,
  CcipFormField,
  OcipFormField
  //OtherFormField
} from "../fields";*/
import { connect } from "react-redux";

export class Policies extends Component {
  constructor(props) {
    const query = JSON.parse(localStorage.getItem("storeObj"));

    super(props);
    this.state = {
      error: null,
      insuredPolicies: [],
      insuredPoliciesCount: 0,
      isExitWarningModal: false,
      formstep: 0,
      documentIndex: 0,
      uploadeddocuments: "",
      lastPolicyForm: false,
      managePolicyForm: {},
      managePolicyFormObject: {},
      isLoadingModal: false,
      policy_type_skip: "",
      ocr_limits: {},
      ocrAssetId: query.asset_id !== undefined ? query.asset_id : ""
    };
    this.uploadservice = new Uploadservice();
  }

  successnotify = message => toast.success(message);
  errornotify = message => toast.error(message);

  movePreviousform = () => {
    if (this.state.formstep > 0)
      this.setState({
        formstep: this.state.formstep - 1,
        lastPolicyForm: false
      });
    else this.props.history.push("/");
  };

  loadPoliciesform = () => {
    //console.log(this.props.managePolicyForm);
    let policy = this.state.formstep;
    let form = this.state.insuredPolicies[policy];
    if (form !== undefined && this.props.managePolicyForm[form.policy_type]) {
      switch (form.policy_type) {
        case "general": {
          return (
            <GeneralLiabilityForm
              policy_type={form.policy_type}
              ocr_policies={this.state.ocr_limits.general || []}
              insuredPolicies={this.state.insuredPolicies}
              formFilelds={this.props.managePolicyForm.general}
              ref={instance => {
                this.child = instance;
              }}
            />
          );
        }
        case "auto": {
          return (
            <AutomobileLiabilityFrom
              policy_type={form.policy_type}
              ocr_policies={this.state.ocr_limits.auto || []}
              insuredPolicies={this.state.insuredPolicies}
              formFilelds={this.props.managePolicyForm.auto}
              ref={instance => {
                this.child = instance;
              }}
            />
          );
        }
        case "excess": {
          return (
            <ExcessForm
              policy_type={form.policy_type}
              ocr_policies={this.state.ocr_limits.excess || []}
              insuredPolicies={this.state.insuredPolicies}
              formFilelds={this.props.managePolicyForm.excess}
              ref={instance => {
                this.child = instance;
              }}
            />
          );
        }
        case "worker": {
          return (
            <WorkersCompensationForm
              policy_type={form.policy_type}
              ocr_policies={this.state.ocr_limits.worker || []}
              insuredPolicies={this.state.insuredPolicies}
              formFilelds={this.props.managePolicyForm.worker}
              ref={instance => {
                this.child = instance;
              }}
            />
          );
        }
        case "property": {
          return (
            <PropertyForm
              policy_type={form.policy_type}
              ocr_policies={this.state.ocr_limits.property || []}
              insuredPolicies={this.state.insuredPolicies}
              formFilelds={this.props.managePolicyForm.property}
              ref={instance => {
                this.child = instance;
              }}
            />
          );
        }
        case "bond": {
          return (
            <BondForm
              policy_type={form.policy_type}
              ocr_policies={this.state.ocr_limits.bond || []}
              insuredPolicies={this.state.insuredPolicies}
              formFilelds={this.props.managePolicyForm.bond}
              ref={instance => {
                this.child = instance;
              }}
            />
          );
        }
        case "pollution": {
          return (
            <PollutionForm
              policy_type={form.policy_type}
              ocr_policies={this.state.ocr_limits.pollution || []}
              insuredPolicies={this.state.insuredPolicies}
              formFilelds={this.props.managePolicyForm.pollution}
              ref={instance => {
                this.child = instance;
              }}
            />
          );
        }
        case "liability": {
          return (
            <LiabilityForm
              policy_type={form.policy_type}
              ocr_policies={this.state.ocr_limits.liability || []}
              insuredPolicies={this.state.insuredPolicies}
              formFilelds={this.props.managePolicyForm.liability}
              ref={instance => {
                this.child = instance;
              }}
            />
          );
        }
        case "aviation": {
          return (
            <AviationForm
              policy_type={form.policy_type}
              ocr_policies={this.state.ocr_limits.aviation || []}
              insuredPolicies={this.state.insuredPolicies}
              formFilelds={this.props.managePolicyForm.aviation}
              ref={instance => {
                this.child = instance;
              }}
            />
          );
        }
        case "ocip": {
          return (
            <OcipForm
              policy_type={form.policy_type}
              ocr_policies={this.state.ocr_limits.ocip || []}
              insuredPolicies={this.state.insuredPolicies}
              formFilelds={this.props.managePolicyForm.ocip}
              ref={instance => {
                this.child = instance;
              }}
            />
          );
        }
        case "ccip": {
          return (
            <CcipForm
              policy_type={form.policy_type}
              ocr_policies={this.state.ocr_limits.ccip || []}
              insuredPolicies={this.state.insuredPolicies}
              formFilelds={this.props.managePolicyForm.ccip}
              ref={instance => {
                this.child = instance;
              }}
            />
          );
        }
        default: {
          return (
            <OtherForm
              policy_type={form.policy_type}
              ocr_policies={
                this.props.ocr_limits !== undefined
                  ? this.props.ocr_limits[form.policy_type]
                  : [] || []
              }
              policyNumber={this.state.formstep}
              insuredPolicies={this.state.insuredPolicies}
              formFilelds={this.props.managePolicyForm[form.policy_type]}
              key={policy}
              ref={instance => {
                this.child = instance;
              }}
            />
          );
        }
      }
    }
  };

  skipPolicyfunction = async () => {
    if (this.props.userPopupModals.skipPolicyModal) {
      // console.log(this.child.state.cats);
      let values = Object.values(this.child.state.cats[0]);
      var limits = [];

      if (this.child.state.cats[0].limits !== undefined) {
        limits = Object.values(this.child.state.cats[0].limits);
      }

      if (
        values.every(
          element =>
            (typeof element === "string" && element === "") ||
            typeof element === "number" ||
            typeof element === "object"
        ) &&
        limits.every(
          element =>
            (typeof element === "string" && element === "") ||
            typeof element === "number"
        )
      ) {
        this.setState({
          isSkipPolicieModal: true,
          policy_type_skip: this.child.getTitle()
        });
      } else {
        this.setState({ isSkipPolicieModal: true, policy_type_skip: "" });
      }
    } else {
      let newState = this.state.managePolicyFormObject;
      delete newState[this.child.state.policy_type];

      this.setState(
        {
          formstep: this.state.formstep + 1,
          lastPolicyForm: false,
          isSkipPolicieModal: false,
          managePolicyFormObject: newState,
          ocr_limits: {
            ...this.state.ocr_limits,
            [this.child.state.policy_type]: 0
          }
        },
        () => {
          if (this.state.formstep === this.state.insuredPoliciesCount)
            this.setState({
              formstep: this.state.formstep - 1,
              lastPolicyForm: true
            });
        }
      );
    }
  };

  continueSkipPolicieModal = event => {
    if (this.childSkipPolicy.state.isHide) {
      this.props.userPopupModals.skipPolicyModal = false;
      this.uploadservice
        .updateUserModalPopup(this.props.userPopupModals)
        .then(res => {
          if (res.status === false) {
            this.errornotify("Some went wrong..");
          } else {
            this.props.changeUserModalBox(this.props.userPopupModals);
          }
        });
    }
    let newState = this.state.managePolicyFormObject;
    delete newState[this.child.state.policy_type];

    this.setState(
      {
        formstep: this.state.formstep + 1,
        lastPolicyForm: false,
        isSkipPolicieModal: false,
        managePolicyFormObject: newState,
        ocr_limits: {
          ...this.state.ocr_limits,
          [this.child.state.policy_type]: 0
        }
      },
      () => {
        if (this.state.formstep === this.state.insuredPoliciesCount)
          this.setState({
            formstep: this.state.formstep - 1,
            lastPolicyForm: true
          });
      }
    );
  };

  hideSkipPolicieModal = event => {
    this.setState({ isSkipPolicieModal: false });
  };

  exitWarningModalFunction = event => {
    if (this.props.userPopupModals.exitFormModal)
      this.setState({ isExitWarningModal: true });
    else
      window.parent.postMessage("closeIframe", "*");
  
  };

  ExitWarningModalConfirm = event => {
    if (this.childExitWarning.state.isHide) {
      this.props.userPopupModals.exitFormModal = false;
      this.uploadservice
        .updateUserModalPopup(this.props.userPopupModals)
        .then(res => {
          if (res.status === false) {
            this.errornotify("Some went wrong..");
          }
        });
    }

    window.parent.postMessage("closeIframe", "*");
  };

  hideExitWarningModal = event => {
    this.setState({ isExitWarningModal: false });
  };

  hideWelcomeModal = event => {
    this.props.changeisWelcomeModal(false);
  };

  continueWelcomeModal = event => {
    if (this.childWelcome.state.isHide) {
      this.props.userPopupModals.wellcomePolicyModal = false;
      this.uploadservice
        .updateUserModalPopup(this.props.userPopupModals)
        .then(res => {
          if (res.status === false) {
            this.errornotify("Some went wrong..");
          }
        });
    }
    this.props.changeisWelcomeModal(false);
  };

  compare = (a, b) => {
    const bandA = a.sortOrder;
    const bandB = b.sortOrder;

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  async componentDidMount() {
    this.props.changeIsTabWorking(true);

    await this.uploadservice.getInsuredPolicies().then(res => {
      this.setState({ insuredPolicies: res, insuredPoliciesCount: res.length });
    });

    await this.uploadservice.getUploadeddocuments().then(res => {
      this.setState({ uploadeddocuments: res });
    });

    this.state.uploadeddocuments.sort(this.compare);

    if (this.state.ocrAssetId !== "") {
      await this.uploadservice.getOcrData(this.state.ocrAssetId).then(res => {
        if(res.limits){
            this.setState({ ocr_limits: res.limits });
        }else{
            this.setState({ ocr_limits: res });
        }
        let url = res.url;
        console.log(res);
//         let url = "https://rem-dev-ocr-docs.s3.amazonaws.com/47511/49248/1586013822974-1585975157791-certholder-extra-5730d2c8d227b361776903.pdf";
        let filename = url.substring(url.lastIndexOf("/") + 1);
        this.setState(prevState => ({
          uploadeddocuments: [
            ...prevState.uploadeddocuments,
            {
              originalname: filename,
              doc_name_client: filename,
              doc_name_local: url,
              doc_id: 1234567890,
              docCategries: { "0": "accord" },
              sortOrder: 1
            }
          ]
        }));
      });
    } else if (this.props.OcrAssetId !== "") {
      this.setState({ isLoadingModal: true });
      let d = new Date();
      let d2 = null;
      let ocrComplete = false;
      let ocrFailed = false;
      // console.log("Checking............................");
      do {
        d2 = new Date();
        // console.log(d2);
        try {
          let checkOcr;
          await this.uploadservice
            .ocrComplete(this.props.OcrAssetId)
            .then(res => {
              checkOcr = JSON.parse(res);
            });
          // console.log(checkOcr);
          if (!checkOcr["found"]) {
            // console.log("failed - not found");
            ocrFailed = true;
          }
          if (checkOcr["ocr_done"]) {
            console.log("success");
            ocrComplete = true;
          }
        } catch (e) {
          console.log("failed - error");
          ocrFailed = true;
        }
      } while (d2 - d < 100000 && !ocrComplete && !ocrFailed);
      if (ocrComplete) {
        this.props.changePolicyFormData({});
        this.props.changeisWelcomeModal(true);
      }
      this.setState({ isLoadingModal: false });
      console.log(this.state);
      await this.uploadservice.getOcrData(this.props.OcrAssetId).then(res => {
        console.log(res);
        if(res.limits){
            this.setState({ ocr_limits: res.limits });
        }else{
            this.setState({ ocr_limits: res });
        }
      });
    }

    var tempobj = getObject(this.state.insuredPolicies);

    if (Object.keys(this.props.managePolicyForm).length === 0) {
      await this.setState(
        {
          managePolicyForm: {
            ...this.state.managePolicyForm,
            ...tempobj
          }
        },
        () => {
          this.props.changePolicyFormData(this.state.managePolicyForm);
        }
      );
    } else {
      await this.setState({
        managePolicyForm: { ...this.props.managePolicyForm }
      });
    }
  }

  changeForm = async () => {
    let policy = this.state.formstep;
    let form = this.state.insuredPolicies[policy];
    let formType = form.policy_type;

    if (this.state.lastPolicyForm || (await this.child.getFormValue())) {
      this.setState(
        {
          managePolicyFormObject: this.state.lastPolicyForm
            ? { ...this.state.managePolicyFormObject }
            : {
                ...this.state.managePolicyFormObject,
                [formType]: this.child.state.cats
              },

          managePolicyForm: this.state.lastPolicyForm
            ? { ...this.state.managePolicyForm }
            : {
                ...this.state.managePolicyForm,
                [formType]: this.child.state.cats
              },

          formstep: this.state.formstep + 1,
          ocr_limits: {
            ...this.state.ocr_limits,
            [formType]: 0
          }
        },
        () => {
          this.props.changePolicyFormData(this.state.managePolicyForm);
          if (this.state.formstep === this.state.insuredPoliciesCount) {
            this.submitPolicyForm(this.state.managePolicyFormObject);
          }
        }
      );
    }
  };
  
  handleDocumentUpload = event => {

    let myForm = document.getElementById("myForm");
    let formData = new FormData(myForm);
    if (this.state.fileobj) {
      formData.append("file", this.state.fileobj);
    }
    let tempthis = this;

    let checkedDocCategoies = JSON.stringify({ ...this.state.checkedItems });
    formData.append("docCategries", checkedDocCategoies);
    this.uploadservice.uploadedDocumentsService(formData).then(res => {
      if (res.status) {
        tempthis.uploadedDocumentsuccessfully(res.data);
      } else {
        tempthis.setState({ isLoadingModal: false });
        tempthis.errornotify(res.msg);
      }
    });
  };

  submitPolicyForm = formdata => {
    if (Object.keys(formdata).length === 0) {
      this.setState({
        formstep: this.state.formstep - 1,
        lastPolicyForm: false
      });
      this.errornotify("Please fill at least one policy form.");
      return;
    }

    this.setState({ isLoadingModal: true, formstep: this.state.formstep - 1 });

    let tempthis = this;
    this.uploadservice.submitPolicyFormService(formdata, this.state.ocrAssetId).then(res => {
        
      if (res.status) {
        this.setState({ isLoadingModal: false });
        window.parent.postMessage(
          "submitSuccesscloseIframe",
          "*"
        );
      } else {
        this.setState({ isLoadingModal: false });
        tempthis.errornotify(res.msg);
        this.props.history.push("/policies");
      }
    });
  };

  render() {
    const { uploadeddocuments, documentIndex, managePolicyForm } = this.state;
    var documenturl = "";
    if (
      Object.keys(managePolicyForm).length > 0 &&
      uploadeddocuments !== "" &&
      uploadeddocuments[documentIndex]
    ) {
      let fileName = uploadeddocuments[documentIndex].doc_name_client;
      let fileExtendtion = fileName.slice(
        ((fileName.lastIndexOf(".") - 1) >>> 0) + 2, ((fileName.lastIndexOf(".") - 1) >>> 0) + 5
      );
      console.log("fileName");
      console.log(fileName);
      console.log(fileExtendtion);
      documenturl = uploadeddocuments[documentIndex].doc_name_local;

      if (fileExtendtion === "pdf") {
        documenturl = (
          <iframe
            src={documenturl}
            width="100%"
            height="100%"
            scrolling="no"
            title="iframe"
          ></iframe>
        );
      } else {
        documenturl = <img src={documenturl} alt="doc" />;
      }
    }
    return (
      <React.Fragment>
        <WelcomeModal
          isWelcomeModal={this.props.isWelcomeModal}
          isWelcomeModalUserTable={
            this.props.userPopupModals.wellcomePolicyModal
          }
          hideWelcomeModal={this.hideWelcomeModal}
          continueWelcomeModal={this.continueWelcomeModal}
          ref={instance => {
            this.childWelcome = instance;
          }}
        />

        <ExitWarningModal
          isExitWarningModal={this.state.isExitWarningModal}
          hideExitWarningModal={this.hideExitWarningModal}
          ExitWarningModalConfirm={this.ExitWarningModalConfirm}
          ref={instance => {
            this.childExitWarning = instance;
          }}
        />

        <SkipPolicieModal
          isSkipPolicieModal={this.state.isSkipPolicieModal}
          hideSkipPolicieModal={this.hideSkipPolicieModal}
          continueSkipPolicieModal={this.continueSkipPolicieModal}
          policy_type_skip={this.state.policy_type_skip}
          ref={instance => {
            this.childSkipPolicy = instance;
          }}
        />

        <div className="custom-container">
          <div className="custom-popup submitInsurance-form">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <img src={icon1} alt="icon1" /> SUBMIT INSURANCE
                </h5>
                <button
                  type="button"
                  onClick={() => this.exitWarningModalFunction()}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src={icon2} alt="icon2" />
                </button>
              </div>
              <div className="modal-body">
                <div className="custom-breadcrumb desktop">
                  <div className="step1">
                    <Link to="/">1. Upload Documents</Link>
                  </div>
                  <div className="step2">
                    <Link to="/policies">2. Check Policies</Link>
                  </div>
                </div>
                <div className="review-document-popup-body">
                  <div className="review-doc-details">
                    {this.loadPoliciesform()}
                  </div>

                  <div className="review-doc-img">
                    <div className="step2 mobile">
                      <span>2. Check Policies</span>
                    </div>
                    <div className="imgBox1">{documenturl}</div>
                  </div>
                  <div className="popup-footer">
                    <div className="popup-footerIn">
                      <div className="btn-box">
                        <button
                          type="button"
                          className="btn btn-bordered"
                          onClick={() => this.exitWarningModalFunction()}
                        >
                          Exit
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => this.movePreviousform()}
                        >
                          Back
                        </button>
                      </div>
                      <div className="btn-box">
                        <button
                          type="button"
                          className="btn btn-ternary"
                          disabled={this.state.lastPolicyForm}
                          onClick={() => this.skipPolicyfunction()}
                        >
                          Skip Policy
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            this.changeForm();
                          }}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>

                  {uploadeddocuments.length > 1 ? (
                    <div className="btn-box next-prev-btns text-center d-flex align-items-center justify-content-center">
                      <button
                        type="button"
                        className="btn"
                        disabled={documentIndex === 0}
                        onClick={() =>
                          this.setState({ documentIndex: documentIndex - 1 })
                        }
                      >
                        Previous
                      </button>
                      <button
                        type="button"
                        className="btn"
                        disabled={
                          uploadeddocuments.length === documentIndex + 1
                        }
                        onClick={() =>
                          this.setState({ documentIndex: documentIndex + 1 })
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadingModal isLoadingModal={this.state.isLoadingModal} />
        <ToastContainer />
      </React.Fragment>
    );
  }
}

const MapStateToProps = state => {
  return {
    isWelcomeModal: state.isWelcomeModal,
    userPopupModals: state.userPopupModals,
    ocr_limits: state.accordObject,
    managePolicyForm: state.policyFormdata,
    isTabWorking: state.isTabWorking,
    OcrAssetId: state.OcrAssetId
  };
};

const MapDispatchToProps = dispatch => {
  return {
    changeisWelcomeModal: isWelcomeModal => {
      dispatch({ type: "CHANGE_WELCOME_MODAL_VALUE", payload: isWelcomeModal });
    },
    changeUserModalBox: userPopupModals => {
      dispatch({ type: "CHANGE_VALUE_MODAL", payload: userPopupModals });
    },
    changeAccordObject: accordObject => {
      dispatch({ type: "CHANGE_ACCORD_OBJECT", payload: accordObject });
    },
    changeIsTabWorking: isTabWorking => {
      dispatch({ type: "CHANGE_VALUE", payload: isTabWorking });
    },
    changePolicyFormData: data => {
      dispatch({ type: "CHANGE_POLICY_FORM_DATA", payload: data });
    }
  };
};

export default connect(MapStateToProps, MapDispatchToProps)(Policies);
